.swiper-container {
    margin-left: -15px;
    width: 100vw;
    height: auto;
    max-width: 1366px;

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    img {
        width: 100%;
    }

    .swiper-button {
        &-next, &-prev {
            width: 27px;
            height: 30px;
            opacity: 0.8;
        }
    }
}